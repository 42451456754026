import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="h-100 w-100 d-flex">
      <Result
        className="m-auto"
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button
            type="primary"
            onClick={() => navigate("/", { replace: true })}
          >
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
