import React from "react";
import "../../assets/scss/page-auth/auth.css";
import { LogoAuthPage } from "./auth.page";
import { Button, Form, Input, Modal } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { apiLogin } from "../../services/auth.service";
import Cookies from "js-cookie";
// import "boxicons";
const LoginPage = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(apiLogin, {
    onSuccess: (response) => {
      Cookies.set("token", response.data.token);
      Cookies.set("role", response.data.role);
      navigate("/dashboard");
    },
    onError: (error) => {
      Modal.error({
        content: <div>{error.response.data.error}</div>,
      });
    },
  });
  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          {/* <!-- Register --> */}
          <div className="card">
            <div className="card-body">
              {/* <!-- Logo --> */}
              <LogoAuthPage />
              {/* <!-- /Logo --> */}
              <h5 className="mb-2 text-center">
                Assalamu'alaikum Warahmatullahi Wabarakatuh
              </h5>
              <p className="mb-4 text-center">Silakan masukkan data Anda</p>

              <Form
                className="mb-3"
                name="basis"
                layout="vertical"
                // action="index.html"
                onFinish={(values) => mutate(values)}
              >
                <Form.Item
                  className="mb-3"
                  name="username"
                  label="Username"
                  rules={[
                    {
                      required: true,
                      message: "kolom ini harus diisi!",
                    },
                  ]}
                  required={false}
                >
                  <Input
                    className="form-input"
                    placeholder="masukkan username Anda"
                  />
                </Form.Item>

                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">Password</label>
                    <p
                      className="mb-0 text-primary"
                      onClick={() =>
                        navigate("/auth/recovery-password", { replace: true })
                      }
                    >
                      <small>Lupa Password?</small>
                    </p>
                  </div>
                  <Form.Item
                    className=""
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "kolom ini harus diisi!",
                      },
                      {
                        min: 5,
                        message: "minimal 5 karakter",
                      },
                    ]}
                    required={false}
                  >
                    <Input.Password
                      type="password"
                      className="form-input"
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    {/* <span className="input-group-text cursor-pointer">
                      <i className="bx bx-hide"></i>
                    </span> */}
                  </Form.Item>
                </div>

                <Form.Item className="mb-3">
                  <Button
                    className="btn btn-primary w-100 w-full h-fit"
                    // type="submit"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Sign in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          {/* <!-- /Register --> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
