import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/images/auth-logo.png";

const AuthPage = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export function LogoAuthPage() {
  return (
    <div className="app-brand justify-content-center">
      <span className="app-brand-link gap-2 justify-content-center">
        <span className="app-brand-logo demo" style={{ width: "80%" }}>
          <img src={logo} className="w-100" alt="logo" />
        </span>
      </span>
    </div>
  );
}

export default AuthPage;
