import Cookies from "js-cookie";
import { BaseService } from "./base.service";

export const DataAbsen = async (month) => {
  const role = Cookies.get("role");
  const request = await BaseService().get(`/sipiat/${role}/lists-absen`, {
    params: { month },
  });
  // console.log(request.data.data);
  return request.data.data;
};

export const DataIzin = async (month) => {
  const role = Cookies.get("role");
  const request = await BaseService().get(`/sipiat/${role}/lists-izin`, {
    params: { month },
  });
  return request.data.data;
};

export const submitIzin = async (values) => {
  const request = await BaseService().post(
    "/sipiat/pegawai/submit-izin",
    values
  );
  // console.log(request.data);

  return request.data.success;
};

export const updateStatus = async (values) => {
  // const request = await BaseService().post("/sipiat/kepsek/acc-izin", values);
  return console.log(values);

  // return request.data.success;
};
