import { MenuOutlined } from "@ant-design/icons";
import React from "react";
import logo from "../../../assets/images/logo.png";
import { useNavigationStore } from "../../../stores/navigation.store";

const NavbarLayout = (props) => {
  const { title } = useNavigationStore();

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center justify-content-between bg-navbar-theme"
      id="layout-navbar"
      style={{
        zIndex: "99",
      }}
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0">
        <span className="nav-item nav-link px-0 me-xl-4" onClick={props.click}>
          <MenuOutlined />
        </span>
      </div>
      <div className="navbar-nav align-items-xl-center me-xl-0">
        <h6 className="mb-0">{title}</h6>
      </div>

      <div className="d-flex align-items-center" id="navbar-collapse">
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* <!-- User --> */}
          <div className="avatar">
            <img
              src={logo}
              alt="avatar"
              className="w-px-40 h-auto rounded-circle border"
            />
          </div>
          {/* <!--/ User --> */}
        </ul>
      </div>
    </nav>
  );
};

export default NavbarLayout;
