import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { publicRoute } from "../utils/route";
import dayjs from "dayjs";
import "dayjs/locale/id";
dayjs.locale("id");

const BasePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const token = Cookies.get("token");
    const isLoggedIn = token ? true : false;
    if (
      (publicRoute.includes(location.pathname) || location.pathname === "/") &&
      isLoggedIn
    ) {
      return navigate("/dashboard", { replace: true });
    }

    if (
      (!publicRoute.includes(location.pathname) || location.pathname === "/") &&
      !isLoggedIn
    ) {
      return navigate("/auth/login", { replace: true });
    }
  }, [navigate, location]);
  return (
    <>
      <Outlet />
    </>
  );
};

export default BasePage;
