import React from "react";
import "../../assets/scss/page-auth/auth.css";
import { LogoAuthPage } from "./auth.page";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";

const RecoveryPage = () => {
  const navigate = useNavigate();
  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner py-4">
          {/* <!-- Forgot Password --> */}
          <div className="card">
            <div className="card-body">
              {/* <!-- Logo --> */}
              <LogoAuthPage />
              {/* <!-- /Logo --> */}
              <h4 className="mb-2">Lupa Password? 🔒</h4>
              <p className="mb-4">
                Masukkan email Anda dan Kami akan mengirimkan instruksi untuk
                atur ulang password Anda
              </p>
              <Form
                name="basis"
                layout="vertical"
                // initialValues={{ remember: true }}
                // onFinish={(values) => mutate(values)}
              >
                <Form.Item
                  className="mb-4"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "kolom ini harus diisi!",
                    },
                  ]}
                  required={false}
                >
                  <Input
                    type="text"
                    className=""
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    autoFocus
                  />
                </Form.Item>
                <Form.Item className="mb-3">
                  <Button className="bbtn btn-primary w-100 w-full h-fit">
                    Send Reset Link
                  </Button>
                </Form.Item>
              </Form>
              <div className="text-center">
                <p
                  onClick={() => navigate("/auth/login", { replace: true })}
                  className="d-flex align-items-center justify-content-center"
                >
                  <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                  Back to login
                </p>
              </div>
            </div>
          </div>
          {/* <!-- /Forgot Password --> */}
        </div>
      </div>
    </div>
  );
};

export default RecoveryPage;
