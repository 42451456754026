import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/global.css";
import "./assets/scss/core.css";
import "./assets/scss/theme-default.css";
import "./assets/scss/demo.css";
import Application from "./components/application";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>
);
