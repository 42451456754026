import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigationStore } from "../../stores/navigation.store";
import { Collapse, DatePicker, Divider, Spin, theme } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useQuery } from "react-query";
import { DataAbsen } from "../../services/data.service";

dayjs.extend(customParseFormat);
// const { RangePicker } = DatePicker;
// const dateFormat = "YYYY/MM/DD";
// const weekFormat = "MM/DD";
const monthFormat = "MM-YYYY";

const AbsenPagen = () => {
  const { changeTitle } = useNavigationStore();
  const { token } = theme.useToken();
  const [month, setMonth] = useState(dayjs());
  const panelStyle = {
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const { data, isLoading } = useQuery({
    queryKey: ["id", month],
    queryFn: () => DataAbsen(month.format("YYYY-MM")),
  });
  // console.log(data);

  useEffect(() => {
    changeTitle("Absensi");
  }, [changeTitle]);

  return (
    <Content>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12 col-lg-12 order-2 mb-4">
            <div className="card">
              <div className="card-body pt-3 pb-3">
                <p className="mb-1">Pilih Bulan</p>
                <DatePicker
                  format={monthFormat}
                  picker="month"
                  className="w-100"
                  onChange={(value) => setMonth(value)}
                  value={month}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 order-2 mb-4">
            <Spin spinning={isLoading}>
              <div className="card">
                <div className="card-body p-0">
                  {(data ?? []).length > 0 ? (
                    <Collapse
                      bordered={false}
                      accordion={true}
                      loading={isLoading}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      style={{
                        background: token.colorBgContainer,
                      }}
                      items={(data ?? []).map((item) => ({
                        key: item.id,
                        label: dayjs(item.tanggal).format("DD MMMM YYYY"),
                        children: (
                          <>
                            <p className="d-flex mb-0 justify-content-between">
                              <span style={{ width: "40%" }}>Absen Datang</span>
                              <span style={{ width: "25%" }}>{item.masuk}</span>
                              <span style={{ width: "35%" }}>
                                {item.keterangan_masuk}
                              </span>
                            </p>
                            <Divider style={{ margin: "10px 0px" }} />
                            <p className="d-flex mb-0 justify-content-between">
                              <span style={{ width: "40%" }}>Absen Pulang</span>
                              <span style={{ width: "25%" }}>
                                {item.pulang}
                              </span>
                              <span style={{ width: "35%" }}>
                                {item.keterangan_pulang}
                              </span>
                            </p>
                          </>
                        ),
                        style: panelStyle,
                      }))}
                    />
                  ) : (
                    <div className="card">
                      <div className="card-body text-center">
                        <p className="mb-0 fw-bold">tidak ada data</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default AbsenPagen;
