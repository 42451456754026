import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { useNavigationStore } from "../../stores/navigation.store";

const DashboardPage = () => {
  const { changeTitle } = useNavigationStore();

  useEffect(() => {
    changeTitle("Dashboard");
  }, [changeTitle]);

  return (
    <Content>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-12 mb-4 order-0">
            <div className="card">
              <div className="d-flex align-items-end row">
                <div className="col-sm-7">
                  <div className="card-body">
                    <h5 className="card-title text-primary">
                      Congratulations John! 🎉
                    </h5>
                    <p className="mb-4">
                      You have done <span className="fw-medium">72%</span> more
                      sales today. Check your new badge in your profile.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default DashboardPage;
