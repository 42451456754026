import { ConfigProvider } from "antd";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { Router1 } from "./routers/router_1";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});
const Application = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <RouterProvider router={Router1} />
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default Application;
