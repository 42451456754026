import { createBrowserRouter } from "react-router-dom";
import BasePage from "../../pages/base_page";
import NotFoundPage from "../../pages/error/notfound.page";
import LoginPage from "../../pages/auth/login.page";
import AuthPage from "../../pages/auth/auth.page";
import DashboardPage from "../../pages/home/dashboard.page";
import RecoveryPage from "../../pages/auth/recovery.page";
import SidebarLayout from "../layouts/vertical/sidebar.layout";
import AbsenPagen from "../../pages/home/absen.page";
import IzinPage from "../../pages/home/izin.page";

export const Router1 = createBrowserRouter([
  {
    path: "/",
    element: <BasePage />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/auth",
        element: <AuthPage />,
        errorElement: <NotFoundPage />,
        children: [
          {
            path: "login",
            element: <LoginPage />,
            errorElement: <NotFoundPage />,
          },
          {
            path: "recovery-password",
            element: <RecoveryPage />,
            errorElement: <NotFoundPage />,
          },
        ],
      },
      {
        path: "/dashboard",
        element: (
          <SidebarLayout>
            <DashboardPage />
          </SidebarLayout>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/info-absen",
        element: (
          <SidebarLayout>
            <AbsenPagen />
          </SidebarLayout>
        ),
        errorElement: <NotFoundPage />,
      },
      {
        path: "/info-izin",
        element: (
          <SidebarLayout>
            <IzinPage />
          </SidebarLayout>
        ),
        errorElement: <NotFoundPage />,
      },
    ],
  },
]);
