import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigationStore } from "../../stores/navigation.store";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  theme,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CaretRightOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineHourglass,
} from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import {
  DataIzin,
  submitIzin,
  updateStatus,
} from "../../services/data.service";
import Cookies from "js-cookie";

dayjs.extend(customParseFormat);
// const { RangePicker } = DatePicker;
// const dateFormat = "YYYY/MM/DD";
// const weekFormat = "MM/DD";
const monthFormat = "MM-YYYY";
const { TextArea } = Input;

const IzinPage = () => {
  const { changeTitle } = useNavigationStore();
  const { token } = theme.useToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailOpen, setModalDetailOpen] = useState(false);
  const [isModalStatusOpen, setModalStatusOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [month, setMonth] = useState(dayjs());
  const [date, setDate] = useState(dayjs());
  const [form] = Form.useForm();
  const [formUpdateStatus] = Form.useForm();

  const panelStyle = {
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  useEffect(() => {
    changeTitle("Info Izin");
  }, [changeTitle]);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["id", month],
    queryFn: () => DataIzin(month.format("YYYY-MM")),
  });

  const { mutate, isLoading: isLoadingAdd } = useMutation(submitIzin, {
    onSuccess: (response) => {
      if (response) {
        refetch();
        hideAddForm();
        Modal.success({
          content: <div>berhasil</div>,
        });
      } else {
        Modal.error({
          content: <div>gagal</div>,
        });
      }
    },
    onError: (error) => {
      Modal.error({
        content: <div>{error.response.data.error}</div>,
      });
    },
  });

  const sendUpdateStatus = useMutation(updateStatus, {
    onSuccess: (response) => {
      console.log(response);
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideDetail = () => {
    setIsModalOpen(false);
  };

  const showDetail = (item) => {
    setModalData(item);
    showModal();
  };

  const showAddForm = () => {
    setModalDetailOpen(true);
  };

  const hideAddForm = () => {
    form.resetFields();
    setModalDetailOpen(false);
  };

  const showUpdateStatus = (item) => {
    formUpdateStatus.setFieldsValue({
      id: item.id,
    });
    setModalStatusOpen(true);
  };

  const hideStatusUpdate = () => {
    formUpdateStatus.resetFields();
    setModalStatusOpen(false);
  };

  const items = (panelStyle, item) => ({
    key: item.id,
    label: (
      <>
        <p className="d-flex mb-0 justify-content-between align-items-center">
          <span style={{ width: "40%" }}>
            {dayjs(item.tanggal_izin).format("DD MMMM YYYY")}
          </span>
          <span style={{ width: "40%" }}>{item.jenis_izin}</span>
          <span className="icon-box">
            {item.acc === "Y" ? (
              <AiFillCheckCircle className="icon icon-check" />
            ) : item.acc === "N" ? (
              <AiFillCloseCircle className="icon icon-close" />
            ) : (
              <AiOutlineHourglass className="icon icon-eye" />
            )}
          </span>
        </p>
      </>
    ),
    children: (
      <>
        <p className="d-flex mb-0 justify-content-between align-items-center">
          <span>
            {item.acc === "Y"
              ? "Diizinkan"
              : item.acc === "N"
              ? "Tidak Diizinkan"
              : "Menunggu"}
          </span>
          <span className="icon-box">
            {/* <AiFillEye
              className="icon icon-eye"
              onClick={() => showDetail()}
            /> */}
            <Button className="btn-default" onClick={() => showDetail(item)}>
              <small>detail</small>
            </Button>
            {Cookies.get("role") === "kepsek" && (
              <Button
                className="btn-primary ms-2"
                onClick={() => showUpdateStatus(item)}
              >
                <small>Update Status</small>
              </Button>
            )}
          </span>
        </p>
      </>
    ),
    style: panelStyle,
  });

  const onSubmit = (values) => {
    const payload = {
      jenis_izin: values.jenis_izin,
      keperluan: values.keperluan,
      tanggal_izin: dayjs(values.tanggal_izin).format("YYYY-MM-DD HH:mm:ss"),
    };
    mutate(payload);
  };
  const onSubmitStatus = (values) => {
    const payload = {
      id: values.id,
      acc: values.acc,
    };
    sendUpdateStatus.mutate(payload);
  };

  return (
    <Content>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          {Cookies.get("role") === "pegawai" && (
            <div className="col-md-12 col-lg-12 order-2 mb-2">
              <Button
                onClick={() => showAddForm()}
                className="d-flex align-items-center float-end"
              >
                <PlusCircleOutlined className="me-2" />
                <h6 className="mb-0">Permintaan Izin</h6>
              </Button>
            </div>
          )}
          <div className="col-md-12 col-lg-12 order-2 mb-2">
            <div className="card">
              <div className="card-body pt-3 pb-3">
                <h6 className="mb-1">Riwayat Izin</h6>
                {/* <p className="mb-1">Pilih Bulan</p> */}
                <DatePicker
                  format={monthFormat}
                  picker="month"
                  className="w-100"
                  onChange={(value) => setMonth(value)}
                  value={month}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-12 order-2 mb-2">
            <Spin spinning={isLoading}>
              <div className="card">
                <div className="card-body p-0">
                  {(data ?? []).length > 0 ? (
                    <Collapse
                      bordered={false}
                      accordion={true}
                      expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                      )}
                      style={{
                        background: token.colorBgContainer,
                      }}
                      items={(data ?? []).map((item) =>
                        items(panelStyle, item)
                      )}
                      className="collapse-izin"
                    />
                  ) : (
                    <div className="card">
                      <div className="card-body text-center">
                        <p className="mb-0 fw-bold">tidak ada data</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>

      <Modal
        title="Form Permintaan Izin"
        open={isModalDetailOpen}
        onCancel={hideAddForm}
        footer={null}
      >
        <Form
          name="basis"
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          // ref={formRef}
        >
          <div className="row">
            <div className="col-6 col-sm-6 col-lg-6">
              <Form.Item className="mb-4" label="Jenis Izin" name="jenis_izin">
                <Select>
                  <Select.Option value="CUTI">CUTI</Select.Option>
                  <Select.Option value="PULANG CEPAT">
                    PULANG CEPAT
                  </Select.Option>
                  <Select.Option value="SAKIT">SAKIT</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <Form.Item
                className="mb-4"
                label="Tanggal Izin"
                name="tanggal_izin"
                rules={[
                  {
                    required: true,
                    message: "kolom ini harus diisi!",
                  },
                ]}
                required={false}
              >
                <DatePicker
                  className="w-100"
                  onChange={(value) => setDate(value)}
                  value={date}
                />
              </Form.Item>
            </div>
            <div className="col-sm-12 col-lg-6">
              <Form.Item
                className="mb-4"
                label="Keperluan/Keterangan"
                name="keperluan"
                rules={[
                  {
                    required: true,
                    message: "kolom ini harus diisi!",
                  },
                ]}
                required={false}
              >
                <TextArea
                  rows={2}
                  className="bg-white borderrounded-md text-sm placeholder-slate-400 hover:border-red-500 w-full py-1 px-2"
                />
              </Form.Item>
            </div>
            <Form.Item className="mb-0 text-end">
              <Button
                type="primary"
                htmlType="submit"
                key="add"
                loading={isLoadingAdd}
                className="w-1/4 h-fit text-white me-2"
              >
                Kirim Permintaan
              </Button>
              <Button danger key="back" onClick={hideAddForm}>
                Batalkan
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Detail Izin"
        open={isModalOpen}
        onCancel={hideDetail}
        footer={[
          <Button key="back" onClick={hideDetail}>
            Tutup
          </Button>,
        ]}
      >
        <p className="mb-0">
          Tanggal Izin: {dayjs(modalData.tanggal_izin).format("DD MMMM YYYY")}
        </p>
        <p className="mb-4">
          Keperluan:
          <span className="d-block">{modalData.keperluan}</span>
        </p>
        {modalData.acc === "Y" ? (
          <>
            <p className="mb-0 text-center fw-bold">
              Telah DISETUJUI dan DIIZINKAN Sebagaimana Informasi di Atas
            </p>
            <small>catatan: {modalData.catatan}</small>
          </>
        ) : modalData.acc === "N" ? (
          <>
            <p className="mb-0 text-center fw-bold">
              Telah DITOLAK Sebagaimana Informasi di Atas
            </p>
            <small>catatan: {modalData.catatan}</small>
          </>
        ) : (
          <p className="mb-0 text-center fw-bold">Menunggu Konfirmasi</p>
        )}
      </Modal>

      <Modal
        title="Update Status Izin"
        open={isModalStatusOpen}
        onCancel={hideStatusUpdate}
        footer={null}
      >
        <Form
          name="basis"
          layout="vertical"
          form={formUpdateStatus}
          onFinish={onSubmitStatus}
          // ref={formRef}
        >
          <div className="row">
            <div className="col-8 col-sm-8 col-lg-8">
              <Form.Item className="mb-4" name="acc">
                <Select>
                  <Select.Option value="y">Acc Perizinan</Select.Option>
                  <Select.Option value="n">Tolak Perizinan</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item className="mb-3 d-none" name="id" required={false}>
                <Input className="form-input" type="hidden" />
              </Form.Item>
            </div>

            <Form.Item className="mb-0 text-end">
              <Button
                type="primary"
                htmlType="submit"
                key="add"
                loading={sendUpdateStatus.isLoadingStatus}
                className="w-1/4 h-fit text-white me-2"
              >
                Update Status
              </Button>
              <Button danger key="back" onClick={hideStatusUpdate}>
                Batalkan
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Content>
  );
};

export default IzinPage;
