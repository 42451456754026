import axios from "axios";
import { Env } from "../env";
import Cookies from "js-cookie";

export const baseURL = Env.REACT_APP_API_URL;
export const timeout = 30000;

export const baseLocal = "http://localhost:3000";
export const timeoutLocal = 30000;

export const BaseService = () => {
  const token = Cookies.get("token");
  return axios.create({
    baseURL,
    timeout,
    headers: {
      token: `${token}`,
    },
  });
};

export const LocalServices = () => {
  const token = localStorage.getItem("token");
  return axios.create({
    baseLocal,
    timeoutLocal,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
