import React, { useState } from "react";
import {
  AuditOutlined,
  HomeOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Drawer, Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarLayout from "./navbar.layout";
import logo from "../../../assets/images/logo.png";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem(
    "Menu Utama",
    "grp",
    null,
    [
      getItem("Dashboard", "/dashboard", <HomeOutlined />),
      getItem("Absensi", "/info-absen", <UnorderedListOutlined />),
      getItem("Info Izin", "/info-izin", <AuditOutlined />),
    ],
    "group"
  ),
  {
    type: "divider",
  },
];

const SidebarLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [current, setCurrent] = useState(location.pathname);
  const [size, setSize] = useState();

  const logout = async () => {
    try {
      Swal.fire({
        title: "Do you want to sign out?",
        showCancelButton: true,
        confirmButtonText: "Sign out",
      }).then((result) => {
        if (result.isConfirmed) {
          Cookies.remove("token", { path: "/" });
          navigate("/auth/login");
        } else if (result.isDenied) {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickMenu = (data) => {
    navigate(data.key);
    setCurrent(data.key);
  };

  const showDrawer = () => {
    setOpen(true);
    setPlacement("left");
    setSize("default");
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <NavbarLayout click={showDrawer} />
      {children}
      <Drawer
        title={
          <div className="app-brand demo">
            <a href="#!" className="app-brand-link">
              <span className="app-brand-logo demo" style={{ width: "30%" }}>
                <img src={logo} className="w-100" alt="logo" />
              </span>
              <span className="app-brand-text demo menu-text fw-bold ms-2">
                Sipiat
              </span>
            </a>
          </div>
        }
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        size={size}
        key={placement}
        // footer={
        //   <div onClick={logout}>
        //     <LogoutOutlined className="me-1" /> Sign Out
        //   </div>
        // }
      >
        <Menu
          defaultSelectedKeys={[current]}
          mode="inline"
          items={items}
          onClick={handleClickMenu}
        />
        <div className="logout-btn" onClick={logout}>
          <LogoutOutlined className="me-1" /> Sign Out
        </div>
      </Drawer>
    </Layout>
  );
};

export default SidebarLayout;
